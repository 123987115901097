import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Vimeo from "@vimeo/player";

import Figure from "./Figure";
import BlockText from "./BlockText";

import * as styles from "./Fig.module.css";

const FigVideo = ({ image }) => {
  const { ref, inView } = useInView({ rootMargin: "0px" });

  const iFrameRef = useRef();
  const [player, setPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!iFrameRef?.current) return;
    setPlayer(new Vimeo(iFrameRef.current));
  }, [player]);

  useEffect(() => {
    if (!player) return undefined;
    player.pause();
  }, [player]);

  useEffect(() => {
    if (!player) return undefined;
    if (inView && !isPlaying) {
      player.getPaused().then((pause) => {
        if (pause) {
          player.play().then(() => setIsPlaying(true));
        }
      });
    }
    if (!inView && isPlaying) {
      player.getPlayed().then((play) => {
        if (play.length) {
          player.pause().then(() => setIsPlaying(false));
        }
      });
    }
  }, [inView]);

  return (
    <div className={styles.videoRoot} key={image.vimeoId}>
      <div className={styles.figVideoWrapper} ref={ref}>
        <iframe
          ref={iFrameRef}
          src={`https://player.vimeo.com/video/${image.vimeoId}?background=1&transparent=0`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // backgroundColor: "transparent",
          }}
          frameBorder="0"
          allow="autoplay"
        ></iframe>
      </div>
      {image._rawCaption && (
        <div className={styles.videoCaption}>
          <BlockText blocks={image._rawCaption || []} />
        </div>
      )}
    </div>
  );
};

const Fig = (props) => {
  const { mainImage, isHiddenMainImage, figImages } = props;

  return (
    <article className={styles.root}>
      {!isHiddenMainImage && (
        <Figure
          node={mainImage}
          figureClassName={styles.figWrapper}
          figcaptionClassName={styles.figCaption}
          loading={"eager"}
        />
      )}

      {figImages.map((image) => {
        if (image._type === "figure" && image.asset)
          return (
            <Figure
              key={image.asset._id}
              isSanityImage
              node={image}
              height={1600}
              figureClassName={styles.figWrapper}
              className={styles.fig}
              figcaptionClassName={styles.figCaption}
            />
          );
        if (image._type === "vimeo") return <FigVideo key={image.vimeoId} image={image} />;
      })}
    </article>
  );
};

export default Fig;
