import React from "react";
import FigCardGrid from "./FigCardGrid";

import * as styles from "./RelatedFigs.module.css";

const RelatedFigs = ({ nodes }) => {
  return (
    <div className={styles.root}>
      <FigCardGrid nodes={nodes} filter={null} />
    </div>
  );
};

export default RelatedFigs;
