import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/GraphQLErrorList";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Fig from "../components/Fig";
import { imageUrlFor } from "../lib/image-url";
import RelatedFigs from "../components/RelatedFigs";
import SEO from "../components/SEO";
import LayoutContainer, { PageTypes } from "../containers/LayoutContainer";

export const query = graphql`
  # query FigTemplateQuery($id: String!) {
  query FigTemplateQuery($id: String!, $figGroupIds: [String]) {
    fig: sanityFig(id: { eq: $id }) {
      id
      year
      title
      number
      slug {
        current
      }
      mainImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 2400)
          _id
        }
        alt
        _rawCaption
      }
      isHiddenMainImage
      figImages {
        ... on SanityFigure {
          ...ImageWithPreview
          _type
          alt
          _rawCaption
        }
        ... on SanityVimeo {
          title
          _type
          vimeoId
          _rawCaption
        }
      }
    }
    relatedFigsTitle: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      relatedTitle
    }
    relatedFigs: allSanityFig(
      filter: { figGroup: { elemMatch: { id: { in: $figGroupIds, ne: null } } } }
    ) {
      edges {
        node {
          id
          number
          title
          inactive
          slug {
            current
          }
          isSeparateThumbnail
          isVideoThumbnail
          thumbnail {
            ...ImageWithPreview
            alt
          }
          thumbnailVideo {
            vimeoId
          }
          mainImage {
            ...ImageWithPreview
            alt
          }
        }
      }
    }
  }
`;

const FigTemplate = (props) => {
  const { data, errors } = props;
  const fig = data && data.fig;
  const mainImageUrl = fig?.mainImage?.asset
    ? imageUrlFor(fig?.mainImage).width(1200).url()
    : undefined;
  const relatedFigNodes = (props.data || {}).relatedFigs
    ? mapEdgesToNodes(props.data.relatedFigs)
        .filter(filterOutDocsWithoutSlugs)
        .filter((node) => node.id !== fig.id)
    : [];

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {errors && <GraphQLErrorList errors={errors} />}
      {/* @todo add description from fig */}
      {fig && <SEO title={fig.title} imageUrl={mainImageUrl} />}

      <LayoutContainer pageType={PageTypes.FigPage} pageData={data}>
        {fig && <Fig {...fig} />}
        {relatedFigNodes?.length > 0 && <RelatedFigs nodes={relatedFigNodes} />}
      </LayoutContainer>
    </>
  );
};

export default FigTemplate;
